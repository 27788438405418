import { useMemo, useEffect, useReducer, useCallback } from "react";

import http from "src/services/http";

import { AuthContext } from "./auth-context";
import {
  LOGIN,
  LOGOUT,
  INITIAL,
  LOADING,
  VERIFY_EMAIL,
  AUTHENTICATED,
  CREATE_PROFILE,
  UPDATE_PROFILE,
  UNAUTHENTICATED,
} from "./types";

const initialState = {
  user: null,
  token: null,
  loading: false,
};

const reducer = (state, action) => {
  if (action.type === INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      token: action.payload.token,
    }
  }

  if (action.type === LOGIN) {
    window.localStorage.setItem("user", JSON.stringify(action.payload.user));
    window.localStorage.setItem("token", action.payload.token);
    return {
      ...state,
      user: action.payload.user,
      token: action.payload.token,
    }
  }

  if (action.type === VERIFY_EMAIL) {
    window.localStorage.setItem("user", JSON.stringify(action.payload.user));
    return {
      ...state,
      user: action.payload.user,
    }
  }

  if (action.type === CREATE_PROFILE) {
    window.localStorage.setItem("user", JSON.stringify(action.payload.user));
    window.localStorage.setItem("token", action.payload.token);
    return {
      ...state,
      user: action.payload.user,
      token: action.payload.token,
    }
  }

  if (action.type === UPDATE_PROFILE) {
    window.localStorage.setItem("user", JSON.stringify(action.payload.user));
    return {
      ...state,
      user: action.payload.user,
    }
  }

  if (action.type === LOGOUT) {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    return initialState;
  }
  return state;
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const user = window.localStorage.getItem("user") ?
      JSON.parse(window.localStorage.getItem('user')) : null;
    const token = window.localStorage.getItem("token") || null;

    dispatch({
      type: INITIAL,
      payload: {
        user,
        token,
      },
    });
  }, []);

  const login = useCallback(async (email, password) => {
    const { data } = await http.post(`/login`, {
      email,
      password,
    });
    if (data.status) {
      const { user, token } = data;
      dispatch({
        type: LOGIN,
        payload: {
          user,
          token,
        },
      });
      return data;
    }
    return {
      msg: data.msg,
      status: data.status,
    }
  }, []);

  const signup = useCallback(async ({ email, fullName, username, password }) => {
    const { data } = await http.post(`register`, {
      email,
      fullName,
      username,
      password,
    });
    return data;
  }, []);

  const verifyEmail = useCallback(async (token) => {
    const { data } = await http.get(`/verify-email/${token}`);
    if (data.status) {
      const { user } = data;
      dispatch({
        type: VERIFY_EMAIL,
        payload: {
          user,
        },
      });
    }
    return {
      msg: data.msg,
      status: data.status,
    }
  }, []);

  const createProfile = useCallback(async (profileData) => {
    const { data } = await http.post('/create-profile', profileData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (data.status) {
      const { user, token } = data;
      dispatch({
        type: CREATE_PROFILE,
        payload: {
          user,
          token,
        }
      });
    }
    return {
      msg: data.msg,
      status: data.status,
    }
  }, []);

  const editProfile = useCallback(async (profileData) => {
    const { data } = await http.post('/edit-profile', profileData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (data.status) {
      const { user } = data;
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          user,
        }
      });
    }
    return {
      msg: data.msg,
      status: data.status,
    }
  }, []);

  const contactUs = useCallback(async (formData) => {
    const { data } = await http.post('/contact-us', {
      formData,
    });

    return data;
  }, []);

  const subscribe = useCallback(async (email) => {
    const { data } = await http.post('/subscribe', { email });
    return data;
  }, []);

  const logout = useCallback(() => {
    dispatch({
      type: LOGOUT,
    });
  }, []);

  const checkAuthenticated = !!state.user && !!state.token ? AUTHENTICATED : UNAUTHENTICATED;

  const status = state.loading ? LOADING : checkAuthenticated;

  const memoizedValue = useMemo(() => ({
    user: state.user,
    loading: status === LOADING,
    authenticated: status === AUTHENTICATED,
    unauthenticated: status === UNAUTHENTICATED,
    login,
    logout,
    signup,
    contactUs,
    subscribe,
    editProfile,
    verifyEmail,
    createProfile,
  }), [
    status,
    state.user,
    login,
    logout,
    signup,
    contactUs,
    subscribe,
    editProfile,
    verifyEmail,
    createProfile,
  ]);

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}
