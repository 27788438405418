import { useCallback, useState } from "react";
import {
  Box,
  Link,
  Stack,
  Divider,
  Container,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useSnackbar } from 'src/components/snackbar';

import Logo from "src/components/logo";
import Iconify from "src/components/iconify";
import { useRouter } from "src/routes/hooks";
import validator from "validator";
import { useAuthContext } from "src/auth/hooks";
import { paths } from "src/routes/paths";

const FooterLink = ({ to, children }) => {
  const router = useRouter();
  const theme = useTheme();
  return (
    <Link
      fontWeight="bold"
      textAlign="start"
      component="button"
      fontFamily="Open sans"
      onClick={() => {
        router.push(to);
      }}
      color={theme.palette.primary.dark}
    >
      {children}
    </Link>
  );
};

const SocialLink = ({ href, icon }) => {
  return (
    <Link
      href={href}
      target="_blank"
    >
      <Iconify icon={icon} width={30} />
    </Link>
  );
}

export default function Footer() {
  const theme = useTheme();

  const { subscribe } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);

  const handleChange = (e) => {
    setIsFormValid(true);
    setEmail(e.target.value);
  }

  const handleSubmit = useCallback(async () => {
    if (email === '' || !validator.isEmail(email)) {
      setIsFormValid(false);
    } else {
      try {
        const data = await subscribe(email);
        if (data.status) {
          enqueueSnackbar(data.msg);
          setEmail('');
        } else {
          enqueueSnackbar(data.msg, {
            variant: 'error',
          });
          console.log(data?.err);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [email, enqueueSnackbar, subscribe]);

  return (
    <Box component="footer" sx={{
      paddingTop: "3rem",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(welcome-bg.png)`,
    }}>
      <Container maxWidth="xl">
        <Stack
          direction={{
            md: "row",
            xs: "column",
          }}
          alignItems={{
            md: "center",
            xs: "start",
          }}
          paddingBottom="3rem"
          justifyContent={{
            md: "space-between",
            xs: "start",
          }}
          gap={{
            xs: "2rem",
          }}
        >
          <Logo sx={{
            width: {
              lg: "20rem",
              md: "16rem",
              xs: "14rem",
            },
          }} />
          <Stack
            direction={{
              md: "row",
              xs: "column",
            }}
            gap={{
              lg: "2rem",
              xs: "1rem",
            }}>
            <FooterLink to={paths.about}>About</FooterLink>
            <FooterLink to={paths.contactUs}>Contact Us</FooterLink>
            <FooterLink to={paths.tos}>Terms of Service</FooterLink>
            <FooterLink to={paths.privacyPolicy}>Privacy Policy</FooterLink>
            <FooterLink to={paths.guidelines}>Community Guidelines</FooterLink>
            {/* <FooterLink to={paths.blogs}>Blogs</FooterLink> */}
          </Stack>
          <Stack direction="row" gap="1rem">
            <SocialLink href="https://www.linkedin.com/company/xposedinsights" icon="devicon:linkedin" />
            <SocialLink href="#" icon="prime:twitter" />
            <SocialLink href="#" icon="devicon:facebook" />
          </Stack>
        </Stack>

        <Divider sx={{
          borderRadius: "99px",
          border: `2px solid rgba(61, 193, 238, 0.26)`,
        }} />

        <Box
          sx={{
            padding: {
              md: "3rem 1rem 4rem 1rem",
              xs: "2.4rem 1rem 3rem 1rem",
            },
          }}
        >
          <Stack
            gap={{
              md: "2rem",
              xs: "1rem",
            }}
            direction={{
              sm: "row",
              xs: "column",
            }}
            justifyContent="center"
            alignItems={{
              sm: "center",
              xs: "start",
            }}
            sx={{
              marginBottom: {
                md: "2rem",
                xs: "1rem",
              },
            }}
          >
            <Typography
              fontWeight="bold"
              fontSize={{
                md: "1.125rem",
                xs: "1rem",
              }}
              lineHeight={{
                md: "1.625rem",
                xs: "1.4rem",
              }}
              fontFamily="Open sans"
              textTransform="uppercase"
              color={theme.palette.primary.dark}
            >
              SUBSCRIBE NOW
            </Typography>

            <FormControl sx={{
              m: {
                sm: 1,
                xs: 0,
              },
              width: {
                md: "20rem",
                sm: "14rem",
                xs: "100%",
              }
            }} variant="outlined">
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                id="email"
                type={'email'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="submit"
                      onClick={handleSubmit}
                      edge="end"
                    >
                      <Iconify icon="simple-line-icons:arrow-right" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Email"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `2px solid ${theme.palette.primary.dark}`
                  }
                }}
                value={email}
                onChange={handleChange}
              />
              {
                isFormValid ? null :
                  <Typography fontSize="0.8rem" color="red">Please enter a valid email</Typography>
              }
            </FormControl>

          </Stack>
          <Typography
            padding="2rem 0"
            textAlign="center"
            fontSize="0.875rem"
            fontFamily="Open sans"
            color={theme.palette.grey[600]}
          >
            © 2024 Xposed Insights. All Rights Reserved
          </Typography>
        </Box>


      </Container>
    </Box>
  );
}
