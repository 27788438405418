import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { GuestGuard } from "src/auth/guard";
import AuthLayout from "src/layouts/auth";

const LoginPage = lazy(() => import('src/pages/auth/login'));
const SignUpPage = lazy(() => import('src/pages/auth/sign-up'));
const VerifyEmailPage = lazy(() => import('src/pages/auth/verify-email'));

const auth = {
  path: 'auth',
  element: (
    <Suspense fallback={<div>Loading...</div>}>
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      ),
    },
    {
      path: 'sign-up',
      element: (
        <GuestGuard>
          <SignUpPage />
        </GuestGuard>
      ),
    },
    {
      path: 'verify-email/:token',
      element: (
        <VerifyEmailPage />
      ),
    },
  ],
}

export const authRoutes = [
  {
    path: '/',
    children: [auth],
  }
];
